import {Background, CFlex, CMargin, DText, Flex, Padding} from "../../styles/CommonStyles";
import {AlertIcon, ModalHeader} from "../../styles/modals";
import Text from "../../core/utils/Text";
import Select from "../common/dropdown/Select";
import Input from "../common/input/Input";
import ActionButton from "../common/buttons/ActionButton";
import styled from "styled-components";
import {useTransaction} from "../main/reports/coin-transactions/util";

const TransactionModal = ({
    details,
    onClose
}) => {

    const {
        thirdParty, setThirdParty,
        config,
        onSubmitClicked,
        ga, setGa, otp, setOtp,
        nobitexWithdraw, nobitexError,
        note, setNote, valid, loading, txId, setTxId
    } = useTransaction({details, onClose})

    if (!details.transaction) {
        return <></>
    }

    return (
        <CFlex fw>
            <ModalHeader>
                <DText primary>
                    <Text tid={`${details.action}-${details.type}`} />
                </DText>
                <AlertIcon size={30}/>
            </ModalHeader>
            <CMargin margin={'15px'} />

            <CFlex fw align={'flex-start'}>
                {thirdParty &&
                    <DText main>
                        <Text tid={'select-thirdparty'} />
                    </DText>
                }
                {details?.action === 'approve' &&
                    <>
                    <CMargin margin={'4px'} />
                        <Padding padding={'4px'}>
                            <Select
                                width={'100%'}
                                options={config ? config.thirdPartyWithdraw : []}
                                value={thirdParty}
                                placeHolder={'select-thirdparty'}
                                onValueChange={(idx) => setThirdParty(config.thirdPartyWithdraw[idx])}
                            />
                        </Padding>
                    </>
                }

                {thirdParty === 'nobitex' ?
                    <>
                        <CMargin margin={'8px'} />
                        <Background bg={'mainBg'}>
                            <Input
                                label={'ga'}
                                value={ga}
                                onInputChange={setGa}
                                disabled={!!nobitexWithdraw}
                                code
                            />
                        </Background>
                        <ErrorSpan>{nobitexError}</ErrorSpan>
                        {!!nobitexWithdraw ?
                            <Background bg={'mainBg'}>
                                <Input
                                    label={'otp'}
                                    value={otp}
                                    onInputChange={setOtp}
                                    code
                                />
                            </Background>
                        : null}
                    </>
                : thirdParty === 'handy' ? 
                            <Background bg={'mainBg'}>
                                <Input
                                    label={'txId'}
                                    value={txId}
                                    onInputChange={setTxId}
                                />
                            </Background> :  null}

                <CMargin margin={'8px'} />
                {details?.action !== 'approve' &&
                    <Background bg={'mainBg'}>
                        <Input
                            value={note}
                            onInputChange={(v) => setNote(v)}
                            label={`${details?.action}-reason`}
                        />
                    </Background>
                }
                <CMargin margin={'6px'} />
                <Flex fw style={{ gap: '12px' }}>
                    <ActionButton
                        onClick={onSubmitClicked}
                        active={valid}
                        loading={loading}
                        height={'36px'}
                        width={'144px'}
                    >
                        <Text tid={'submit'} />
                    </ActionButton>
                    <ActionButton
                        onClick={onClose}
                        loading={loading}
                        height={'36px'}
                        width={'144px'}
                    >
                        <Text tid={'cancel'} />
                    </ActionButton>
                </Flex>

            </CFlex>
        </CFlex>
    )
}

const ErrorSpan = styled.div`
  color: ${props => props.theme.mainRed};
  font-size: 0.75rem;
`

export default TransactionModal
