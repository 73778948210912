import {useParams} from "react-router-dom";
import {
    LeftHeadDecoration,
    RightHeadDecoration,
    DetailRow, TxidLink
} from "../../../styles/main/MainCommonStyles";
import {DText, Flex,} from "../../../styles/CommonStyles";
import Text from "../../../core/utils/Text";
import {formatDate, formatNumber} from "../../../core/utils/common";
import {TradeTypeBadge} from "../../../styles/main/orders";
import {getTradeTypeColor} from "../../../core/utils/theme";
import {BASE_URL, SOCKET_URL} from "../../../core/constants/urls";
import {useMainContext} from "../../../core/contexts/main";
import ModalLayout from "../../../components/layout/main/ModalLayout";
import useCoinTransactions from "../../../core/hooks/main/coin-transactions/useCoinTransactions";
import Operation from "../../../components/common/utils/Operation";
import TransactionModal from "../../../components/modals/TransactionModal";
import DetailsLayout from "../../../components/layout/main/DetailsLayout";
import {useGetACoinTransaction} from "../../../core/services/react-query/coining";
import CopyText from "../../../components/common/utils/CopyText";



const CoinTransactionDetails = () => {

    const params = useParams()
    const { lang } = useMainContext()

    const { data: transaction, isFetching, isError } = useGetACoinTransaction(params.detailId)

    const {
        hasWriteAccess,
        onModalClose,
        onOptionClicked,
        modal,
        getTransactionOptions
    } = useCoinTransactions()

    const nobitex = transaction?.thirdParty?.result?.withdraw
    console.log(nobitex)

    return (
        <>
            <DetailsLayout
                isFetching={isFetching}
                isError={isError}
                type={transaction?.status}
            >
                <RightHeadDecoration>
                    <Flex>
                        <img
                            src={SOCKET_URL + `assets/icon/${transaction?.coin}.png`}
                            alt={' '}
                            width={'48px'}
                        />
                    </Flex>
                </RightHeadDecoration>
                <LeftHeadDecoration left={'10px'}>
                    <DText main>
                        {params.detailId}
                    </DText>
                </LeftHeadDecoration>

                {/* content */}
                <Flex fw justify='space-between'>
                    <DText fontSize={'b'} primary>
                        {transaction?.coin?.toUpperCase()}
                    </DText>
                    <Operation
                        options={getTransactionOptions(transaction)}
                        hasWriteAccess={hasWriteAccess}
                        onOptionClicked={(idx) => onOptionClicked(idx, transaction)}
                    />
                </Flex>

                <DetailRow>
                    <DText main>
                        <Text tid={'wallet-address'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <DText primary lineBreak={'anywhere'}>
                            {transaction?.address}
                        </DText>
                    </Flex>
                </DetailRow>
                <DetailRow>
                    <DText main>
                        <Text tid={'transaction-flow'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <DText primary>
                            <Text tid={transaction?.flow} />
                        </DText>
                    </Flex>
                </DetailRow>
                <DetailRow>
                    <DText main>
                        <Text tid={'transaction-status'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <TradeTypeBadge color={getTradeTypeColor(transaction?.status)}>
                            <Text tid={transaction?.status} />
                        </TradeTypeBadge>
                    </Flex>
                </DetailRow>
                <DetailRow>
                    <DText main>
                        <Text tid={'network'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <TradeTypeBadge color={getTradeTypeColor('pending')}>
                            <Text tid={transaction?.network} />
                        </TradeTypeBadge>
                    </Flex>

                </DetailRow>
                {transaction?.txId &&
                    <DetailRow>
                        <DText main >
                            <Text tid={'txid'} />
                        </DText>
                        <Flex justify={'flex-end'}>
                            <TxidLink
                                target='_blank'
                                href={`${BASE_URL}coining/txid/${transaction?.txId}?network=${transaction?.network}&symbol=${transaction?.coin}`}
                            >
                                {transaction?.txId}
                            </TxidLink>
                        </Flex>
                    </DetailRow>
                }

                {transaction?.thirdParty &&
                    <DetailRow>
                        <DText main>
                            <Text tid={'third-party'} />
                        </DText>
                        <Flex justify={'flex-end'}>
                            <TradeTypeBadge color={getTradeTypeColor('limit')}>
                                <Text tid={transaction?.thirdParty?.name} />
                            </TradeTypeBadge>
                        </Flex>
                    </DetailRow>
                }

                <DetailRow>
                    <DText main>
                        <Text tid={'amount'} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <DText primary>
                            {formatNumber(transaction?.amount)}
                        </DText>
                    </Flex>
                </DetailRow>
                <DetailRow>
                    <DText main>
                        <Text tid={`pure-${transaction?.flow}`} />
                    </DText>
                    <Flex justify={'flex-end'}>
                        <DText primary>
                            {formatNumber(transaction?.totalAmount)}
                        </DText>
                    </Flex>
                </DetailRow>

                {transaction?.note &&
                    <DetailRow>
                        <DText main>
                            <Text tid={'note'} />
                        </DText>
                        <DText main style={{ textAlign: 'end' }}>
                            {transaction?.note}
                        </DText>

                    </DetailRow>
                }
                <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                    <DText main>
                        {formatDate(transaction?.createdAt, 'date', lang)}
                    </DText>
                    <DText main>
                        {formatDate(transaction?.createdAt, 'time', lang)}
                    </DText>
                </Flex>

                <ModalLayout
                    open={modal.open}
                    width={'520px'}
                    onClose={onModalClose}
                >
                    <TransactionModal
                        details={modal}
                        onClose={onModalClose}
                    />
                </ModalLayout>
            </DetailsLayout>
            {transaction?.thirdParty ?
                <DetailsLayout
                    style={{ marginTop: '0' }}
                    isFetching={isFetching}
                    isError={isError}
                    type={transaction?.status}
                >
                    <DText primary>
                        <span>{transaction?.thirdParty?.name?.toUpperCase()}</span>
                    </DText>
                    <DetailRow>
                        <DText main>
                            <Text tid={'address'} />
                        </DText>
                        <CopyText justify={'flex-end'} text={nobitex?.address} />
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'id'} />
                        </DText>
                        <CopyText justify={'flex-end'} text={nobitex?.id} />
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'wallet_id'} />
                        </DText>
                        <CopyText justify={'flex-end'} text={nobitex?.wallet_id} />
                    </DetailRow>
                    <DetailRow>
                        <DText main>
                            <Text tid={'amount'} />
                        </DText>
                        <Flex justify={'flex-end'}>
                            <DText primary>
                                {formatNumber(nobitex?.amount)}
                            </DText>
                        </Flex>
                    </DetailRow>
                    {transaction?.thirdParty?.name === 'handy' && 
                        <DetailRow>
                            <DText main>
                                <Text tid={'txId'} />
                            </DText>
                            <Flex justify={'flex-end'}>
                                <DText primary>
                                    {transaction?.txId}
                                </DText>  
                            </Flex>
                        </DetailRow>
                    }
                    {!!nobitex?.blockchain_url ?
                        <DetailRow>
                            <DText main>
                                <Text tid={'blockchain_url'} />
                            </DText>
                            <CopyText justify={'flex-end'} text={nobitex.blockchain_url} />
                        </DetailRow>
                    : null}
                    <Flex fw justify={'space-between'} style={{ marginTop: '20px' }}>
                        <DText main>
                            {formatDate(nobitex?.createdAt, 'date', lang)}
                        </DText>
                        <DText main>
                            {formatDate(nobitex?.createdAt, 'time', lang)}
                        </DText>
                    </Flex>
                </DetailsLayout>
            : null}

        </>

    )
}


export default CoinTransactionDetails
